<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: 'Export'
  },
  dialog: {
    type: Boolean,
    required: true
  },
  message: {
    type: String,
    required: false
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  }
})

const shipStore = useShipStore()

const { currentItem } = storeToRefs(shipStore)

const dialogMessage = computed(() => {
  if (!props.message && currentItem.value) {
    const name = currentItem.value.name
    return 'Do you want to export <strong>' + name + '</strong>?'
  }
  return props.message
})

const emit = defineEmits(['export', 'toggleDialog'])

const exportDialog = computed({
  get() {
    return props.dialog
  },
  set() {
    emit('toggleDialog')
  }
})
</script>

<template>
  <v-dialog v-model="exportDialog" max-width="400" @click:outside="emit('toggleDialog')">
    <v-card class="pa-3">
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text class="pt-3 pl-4" v-html="dialogMessage"></v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          color="primary"
          class="text-none"
          variant="text"
          @click="emit('toggleDialog')"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          class="text-none"
          variant="text"
          @click="emit('export')"
        >
          Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
