<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'

import InputShipFileDialog from '@/components/dialog/InputShipFileDialog.vue'
import ShipPreviewDialog from '@/components/dialog/ShipPreviewDialog.vue'
import UploadDocumentPreviewDialog from '@/components/dialog/UploadDocumentPreviewDialog.vue'

import { useEventsBus } from '@rhdhv/vue-component-library'

import { useDocumentStore } from '@/stores/document'

const shipStore = useShipStore()
const documentStore = useDocumentStore()

const { emit } = useEventsBus()
const {
  items: ships,
  previewFullShipDialog,
  createFullShipDialog,
  previewDocumentDialog,
  importItem,
  uploadLoading,
  importSuccess,
  currentItem: currentShip
} = storeToRefs(shipStore)

const importFile = ref<File | null>(null)

watch(previewFullShipDialog, (value) => {
  if (value) {
    documentStore.resetDocuments()
  } else {
    documentStore.setDocuments(currentShip.value?.document_set || [])
  }
})

watch(importItem, () => {
  if (importItem.value && !uploadLoading.value && importFile.value) {
    createFullShipDialog.value = false
    previewFullShipDialog.value = true
  }
})

async function submitFile() {
  if (importFile.value) {
    const file = importFile.value
    const formData = new FormData()
    formData.append('file', file)
    await shipStore.uploadFile(formData)
  }
}

async function importShip() {
  if (importItem.value) {
    try {
      const ship = await shipStore.import()
      if (ship?.id) {
        await documentStore.uploadDocumentFiles(ship.id)
        const importedMessage = {
          status: 200,
          data: [],
          method: 'POST',
          statusText: 'Imported ship!'
        }
        emit('success', importedMessage)
      }
    } catch (e) {
      console.log(e)
    }
  }
}

function reset() {
  shipStore.toggleCreateFullShipDialog(false)
  previewFullShipDialog.value = false
  importItem.value = null
  importFile.value = null
  documentStore.clearUnsavedDocuments()
  importSuccess.value = false
}

async function finish() {
  shipStore.resetState()
  reset()
  await shipStore.fetchPaginationItems()
  if (ships.value.length) {
    shipStore.setCurrentItem(ships.value[0])
  }
}

async function back() {
  previewFullShipDialog.value = false
  createFullShipDialog.value = true
  importItem.value = null
}

async function close() {
  reset()
}

async function upload(): Promise<void> {
  if (currentShip.value?.id) {
    await documentStore.uploadDocumentFiles(currentShip.value.id)
  }
}

function closeDialog(): void {
  previewDocumentDialog.value = false
}
</script>

<template>
  <InputShipFileDialog v-model="importFile" @submit="submitFile()"></InputShipFileDialog>
  <ShipPreviewDialog
    @back="back"
    @close="close"
    @finish="finish"
    @import="importShip()"
  ></ShipPreviewDialog>
  <UploadDocumentPreviewDialog @upload="upload" @close="closeDialog"></UploadDocumentPreviewDialog>
</template>
