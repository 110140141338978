<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import AppHeader from '@/components/app/AppHeader.vue'
import { RHDHVSnackbar } from '@rhdhv/vue-component-library'

import { useDjangoAuthStore } from '@/stores/authDjango'

const { user, loading, rhdhvLoading } = storeToRefs(useDjangoAuthStore())

const showHeader = computed<boolean>(() => {
  return !!user.value && !(loading.value || rhdhvLoading.value)
})
</script>

<template>
  <v-app>
    <AppHeader v-if="showHeader"></AppHeader>
    <v-main>
      <router-view />
      <RHDHVSnackbar
        style="z-index: 3000"
        :excluded-http-methods="['GET', 'OPTIONS']"
      ></RHDHVSnackbar>
    </v-main>
  </v-app>
</template>

<style lang="scss">
@use '/src/scss/settings.scss';
</style>
