<script lang="ts" setup>
import { computed, type PropType } from 'vue'

import { useShipStore } from '@/stores/ship'
import { storeToRefs } from 'pinia'

import { useDocumentStore } from '@/stores/document'
import type { ShipType } from '@/stores/type/ship.type'

import DetailTab from '@/components/detail/DetailTab.vue'
import ValidationMenu from '@/components/ships/ValidationMenu.vue'
import ActionMenu from '@/components/common/ActionMenu.vue'

const shipStore = useShipStore()
const documentStore = useDocumentStore()
const { currentItem: currentShip } = storeToRefs(shipStore)

type HeadersType = {
  title: string
  align: string
  key: keyof ShipType
}

const props = defineProps({
  item: { type: Object as PropType<ShipType>, required: true },
  previewState: { type: Boolean, default: false },
  chartEvent: { type: String as PropType<string>, required: true },
  tableHeight: { type: Number, default: 70 }
})

const headers = ref<HeadersType[]>([
  { title: 'Capacity', align: 'end', key: 'capacity' },
  { title: 'Overall', align: 'end', key: 'length_overall' },
  { title: 'Breadth', align: 'end', key: 'breadth' },
  { title: 'Laden draught', align: 'end', key: 'laden_draught' },
  { title: 'Displacement', align: 'end', key: 'laden_displacement' }
])

const open = ref(false)

const mooringSideText = computed<string>(() => {
  switch (props.item?.mooring_side) {
    case 'STARBOARD':
      return 'Starboard'
    case 'PORT_SIDE':
      return 'Port side'
    default:
      return '---'
  }
})

const tableHeight = computed<number>(() => {
  return props.previewState ? props.tableHeight - 15 : 40
})

const selectedShip = computed<string>(() => {
  return currentShip.value?.id === props.item?.id ? 'selected-item' : ''
})

function toggleOpen(item: ShipType): void {
  if (props.previewState) {
    return
  }
  shipStore.setCurrentItem(item)
  if (props.item?.has_ship_design || props.item?.has_armour_param) {
    open.value = !open.value
  }
}

const uploadDocumentEnabled = computed<boolean>(() => {
  return documentStore.documentPermissions(currentShip.value)
})

const docsDisabled = computed<boolean>(() => {
  return !currentShip.value?.document_set?.length && !uploadDocumentEnabled.value
})

const clickOnShip = (item: ShipType): void => {
  if (props.previewState) {
    return
  }
  shipStore.setCurrentItem(item)
}
</script>

<template>
  <tr
    v-if="item"
    v-ripple="!previewState"
    :class="[!previewState ? 'clickable hover-event' : '', selectedShip]"
    @click="toggleOpen(item)"
  >
    <td v-if="!previewState" class="px-0 text-center">
      <v-icon v-if="item.has_ship_design || item.has_armour_param" v-bind="props">
        {{ open ? `mdi-chevron-up` : `mdi-chevron-down` }}
      </v-icon>
    </td>
    <v-tooltip :text="item.name">
      <template v-slot:activator="{ props }">
        <td :class="['name-cell', !previewState ? 'pl-1 pr-0' : '']" v-bind="props">
          {{ item.name }}
        </td>
      </template>
    </v-tooltip>
    <td class="pl-1, pr-0">{{ item.fulltype }}</td>
    <td class="text-center">{{ mooringSideText }}</td>
    <td v-for="header in headers" :key="header.key" class="text-center">{{ item[header.key] }}</td>
    <td v-if="!previewState" class="text-center">
      <ValidationMenu
        :ship="item"
        @click="shipStore.setCurrentItem(item)"
        @update="shipStore.update(item)"
      ></ValidationMenu>
    </td>
    <td v-if="!previewState" class="text-center">
      <ActionMenu
        :docs-disabled="docsDisabled"
        :export-auto-cad="currentShip?.has_ship_design"
        :export-excel="currentShip?.has_ship_design || currentShip?.has_armour_param"
        :delete-item="shipStore.deletePermission"
        @click="shipStore.setCurrentItem(item)"
        @docDialog="shipStore.toggleDocumentDialog(true)"
        @export-auto-cad="shipStore.toggleExportAutoCADDialog(true)"
        @export-excel="shipStore.toggleExportExcelDialog(true)"
        @delete="shipStore.toggleDeleteDialog(item)"
      ></ActionMenu>
    </td>
  </tr>
  <tr v-if="item && (item.has_ship_design || item.has_armour_param)">
    <td v-if="open || previewState" class="pl-0 pr-0" colspan="999" @click.stop="clickOnShip(item)">
      <DetailTab :chart-event="chartEvent" :item="item" :table-height="tableHeight"></DetailTab>
      <v-divider v-if="!previewState" :thickness="2"></v-divider>
    </td>
  </tr>
</template>

<style scoped>
.name-cell {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
