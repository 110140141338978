<script lang="ts" setup>
import { useShipStore } from '@/stores/ship'
import { storeToRefs } from 'pinia'
import type { PropType } from 'vue'

const shipStore = useShipStore()
const { createFullShipDialog, uploadLoading } = storeToRefs(shipStore)

const props = defineProps({
  modelValue: { type: [File, null] as PropType<File | null>, required: true }
})

const emit = defineEmits(['update:modelValue', 'submit'])

const dialog = computed({
  get: () => createFullShipDialog.value,
  set: (value: boolean) => {
    shipStore.toggleCreateFullShipDialog(value)
  }
})

const vModel = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})
</script>

<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-card-title> Import ship </v-card-title>
      <v-card-subtitle>File input (using the template): </v-card-subtitle>
      <v-file-input
        density="compact"
        color="primary"
        accept=".xlsx"
        v-model="vModel"
        class="px-4 mt-2"
        hide-details
        label="Upload excel file"
        prepend-icon="mdi-folder-zip"
        truncate-length="50"
      ></v-file-input>
      <v-card-actions class="pt-4">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="uploadLoading"
          color="primary"
          class="text-none"
          variant="text"
          @click="shipStore.toggleCreateFullShipDialog(false)"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="vModel === null || uploadLoading"
          color="primary"
          class="text-none"
          variant="text"
          :loading="uploadLoading"
          @click="emit('submit')"
        >
          Generate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
