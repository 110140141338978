<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { FairleadType, PedestalType, WinchType } from '@/stores/type/ship.type'
import { useEventsBus } from '@rhdhv/vue-component-library'
import {
  FAIRLEAD_COLOR,
  FAIRLEAD_LABEL,
  PEDESTAL_COLOR,
  PEDESTAL_LABEL,
  TAULINE_LABEL,
  WINCH_COLOR,
  WINCH_LABEL
} from '@/components/constantVariables'
import { useFairleadStore } from '@/stores/fairlead'
import { storeToRefs } from 'pinia'

const { options } = storeToRefs(useFairleadStore())

const props = defineProps({
  item: { type: Object as PropType<FairleadType>, required: true },
  winches: { type: Object as PropType<WinchType[]>, required: true },
  pedestals: { type: Object as PropType<PedestalType[]>, required: true },
  itemNumber: { type: Number, required: true },
  index: { type: Number, required: true },
  currentTauline: { type: Number, required: false, default: 0 },
  chartEvent: { type: String as PropType<string>, required: true }
})

const fairleadName = computed<string>(() => {
  return 'F' + (props.itemNumber + 1)
})

const lineType = computed<string>(() => {
  if (options.value['line_type']?.choices?.length) {
    const lineTypeObj = options.value['line_type'].choices.find(
      (choice) => choice.value === props.item.line_type
    )
    return lineTypeObj ? lineTypeObj.display_name : '---'
  }
  return '---'
})

const winchName = computed<string>(() => {
  const winchIndex = props.winches.findIndex((winch) => winch.id === props.item?.winch)
  return winchIndex !== -1 ? 'W' + (winchIndex + 1) : ''
})

const pedestalName = computed<string>(() => {
  const pedestalIndex = props.pedestals.findIndex(
    (pedestal) => pedestal.id === props.item?.pedestal
  )
  return pedestalIndex !== -1 ? 'P' + (pedestalIndex + 1) : ''
})

const clickRow = () => {
  emitter('clickRow')
  emit(props.chartEvent, { id: props.item.id, label: TAULINE_LABEL })
}

const { emit } = useEventsBus()
const emitter = defineEmits(['clickRow'])

const isSelectedTauline = computed<boolean>(() => {
  return props.item.id === props.currentTauline
})
</script>

<template>
  <tr @click.stop="clickRow" :class="{ 'selected-item': isSelectedTauline, clickable: true }">
    <td class="pr-0 text-left">{{ index + 1 }}</td>
    <td class="pa-0" style="width: 1%; font-size: 12px">
      <v-chip
        v-if="fairleadName"
        v-ripple
        class="clickable"
        :color="FAIRLEAD_COLOR"
        label
        size="x-small"
        @click.stop="emit(chartEvent, { id: item.id, label: FAIRLEAD_LABEL })"
      >
        {{ fairleadName }}
      </v-chip>
    </td>
    <td class="pa-0" style="width: 1%">
      <v-chip
        v-if="pedestalName"
        v-ripple
        class="clickable"
        :color="PEDESTAL_COLOR"
        label
        size="x-small"
        @click.stop="emit(chartEvent, { id: item.pedestal, label: PEDESTAL_LABEL })"
      >
        {{ pedestalName }}
      </v-chip>
    </td>
    <td class="pa-0" style="width: 1%">
      <v-chip
        v-if="winchName"
        v-ripple
        class="clickable"
        :color="WINCH_COLOR"
        label
        size="x-small"
        @click.stop="emit(chartEvent, { id: item.winch, label: WINCH_LABEL })"
      >
        {{ winchName }}
      </v-chip>
    </td>
    <td class="pa-0">{{ lineType }}</td>
    <td class="pr-0 border-left-grey">{{ item.x?.toFixed(1) }}</td>
    <td class="pr-0">{{ item.y?.toFixed(1) }}</td>
    <td class="border-right-grey">{{ item.z?.toFixed(1) }}</td>
    <td class="border-right-grey pr-1 pl-1">
      {{ item.minimum_horizontal_angle?.toFixed(1) }}
    </td>
  </tr>
</template>

<style scoped>
.td {
  text-align: center;
}
</style>
