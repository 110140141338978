<script lang="ts" setup>
const props = defineProps({
  deleteItem: { type: Boolean, required: false, default: false },
  docsDisabled: { type: Boolean, required: false, default: false },
  docs: { type: Boolean, required: false, default: true },
  exportExcel: { type: Boolean, required: false, default: false },
  exportAutoCad: { type: Boolean, required: false, default: false }
})

const emit = defineEmits(['docDialog', 'delete', 'exportExcel', 'exportAutoCad', 'click'])
</script>
<template>
  <v-menu :close-on-content-click="true">
    <template v-slot:activator="{ props }">
      <v-btn icon="mdi-dots-vertical" v-bind="props" variant="text" @click="emit('click')"> </v-btn>
    </template>
    <v-list density="compact">
      <v-list-item
        :disabled="docsDisabled"
        @click="$emit('docDialog')"
        prepend-icon="mdi-file-document-outline"
      >
        <v-list-item-title>
          <template v-if="!docsDisabled"> Documents </template>
          <template v-else> No documents </template>
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="exportAutoCad"
        @click="$emit('exportAutoCad')"
        prepend-icon="mdi-export-variant"
      >
        <v-list-item-title> Export to AutoCAD </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="exportExcel"
        @click="$emit('exportExcel')"
        prepend-icon="mdi-file-export-outline"
      >
        <v-list-item-title> Export to Excel </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="deleteItem" @click="$emit('delete')" prepend-icon="mdi-delete-outline">
        <v-list-item-title> Delete </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>
:deep(.v-list-item__prepend) {
  display: unset;
}

:deep(.v-list-item-title) {
  font-size: 14px !important;
}
</style>
