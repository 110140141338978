import { defineStore } from 'pinia'
import type { ValidationStateType, ValidationType } from '@/stores/type/validation.type'
import API from '@/api/apiUrls'
import AppBaseStore from '@/stores/baseStore'
import { useApi } from '@/api/useApi'

const { api } = useApi()

const baseUrl = API.VALIDATION.BASE
const baseStore = new AppBaseStore<ValidationType>(baseUrl, api)

export const useValidationStore = defineStore('validation', {
  state: (): ValidationStateType => ({
    ...baseStore.appState
  }),
  actions: {
    ...baseStore.appActions
  }
})
