<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'
import ShipPreviewParameter from '@/components/import/ShipPreviewParameter.vue'
import MainChart from '@/components/chart/MainChart.vue'

const shipStore = useShipStore()
const { importItem: importShip } = storeToRefs(shipStore)
</script>

<template>
  <v-row no-gutters v-if="importShip" class="ma-0">
    <v-col cols="6">
      <MainChart
        :ship="importShip"
        chart-event="importChartEvent"
        chart-ref="importChart"
      ></MainChart>
    </v-col>
    <v-col class="pa-0 border-left-grey" cols="6">
      <ShipPreviewParameter
        :ship="importShip"
        chart-event="importChartEvent"
      ></ShipPreviewParameter>
    </v-col>
    <v-divider></v-divider>
  </v-row>
</template>
