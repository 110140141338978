<script lang="ts" setup>
import type { PropType } from 'vue'
import ShipPreviewApproval from '@/components/import/ShipPreviewApproval.vue'
import ShipPreviewParameterTable from '@/components/import/ShipPreviewParameterTable.vue'
import { storeToRefs } from 'pinia'
import DocumentTable from '@/components/document/DocumentTable.vue'
import { useShipStore } from '@/stores/ship'
import { useDjangoAuthStore } from '@/stores/authDjango'
import type { ShipType } from '@/stores/type/ship.type'

const props = defineProps({
  ship: { type: Object as PropType<ShipType>, required: true },
  chartEvent: { type: String as PropType<string>, required: true }
})

const { user } = storeToRefs(useDjangoAuthStore())
const { previewDocumentDialog, importSuccess } = storeToRefs(useShipStore())

const isAdmin = computed<boolean>(() => {
  return user.value ? user.value.is_superuser : false
})

const cols = computed<number>(() => {
  return isAdmin.value ? 8 : 12
})
</script>

<template>
  <v-card class="fill-height" flat>
    <template v-if="ship">
      <ShipPreviewParameterTable
        :chart-event="chartEvent"
        :is-admin="isAdmin"
        :ship="ship"
        :table-height="65"
        class="mt-2"
      />
      <v-divider thickness="2"></v-divider>
      <v-row no-gutters>
        <v-col v-if="isAdmin" cols="4" style="border-right: #e4e4e4 solid 2px">
          <ShipPreviewApproval :ship="ship" :table-height="22" />
        </v-col>
        <v-divider v-if="isAdmin" vertical></v-divider>
        <v-col :cols="cols">
          <DocumentTable
            :add-document="!importSuccess"
            :table-height="22"
            @add="previewDocumentDialog = true"
          ></DocumentTable>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>
