<script lang="ts" setup>
import type { PropType } from 'vue'
import { useDocumentStore } from '@/stores/document'
import { storeToRefs } from 'pinia'
import type { DocumentType } from '@/stores/type/pureDocumentType'

const props = defineProps({
  document: { type: Object as PropType<DocumentType>, required: true }
})

const documentStore = useDocumentStore()
const { downloadLoading, uploadLoading } = storeToRefs(documentStore)

const isDownloading = ref<boolean>(false)

watch(downloadLoading, () => {
  if (!downloadLoading.value) {
    isDownloading.value = false
  }
})

function downloadDocument(documentId: number) {
  documentStore.downloadDocument(documentId)
  isDownloading.value = true
}

const emit = defineEmits(['remove'])
</script>

<template>
  <tr style="height: 40px">
    <td class="border-right-grey" style="text-align: center">
      <v-icon v-if="document.id" color="success">mdi-check-bold</v-icon>
      <v-progress-circular
        v-else-if="document.loading && !document.response"
        :size="20"
        indeterminate
      ></v-progress-circular>
      <v-icon color="error" v-else-if="!document.loading && document.failed"
        >mdi-close-thick</v-icon
      >
    </td>
    <td>{{ document.file_name }}</td>
    <td style="text-align: right">
      <v-btn
        v-if="(!uploadLoading && !document.sent) || document.failed || !document.id"
        color="primary"
        density="compact"
        icon="mdi-close-circle-outline"
        variant="text"
        @click="emit('remove')"
      ></v-btn>
      <v-btn
        v-else-if="document.id"
        :loading="downloadLoading && isDownloading"
        density="compact"
        icon="mdi-download"
        variant="text"
        color="primary"
        @click="downloadDocument(document.id)"
      >
      </v-btn>
    </td>
  </tr>
</template>
