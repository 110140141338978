<script lang="ts" setup>
import { useShipStore } from '@/stores/ship'
import { storeToRefs } from 'pinia'
import { useShipTypeStore } from '@/stores/shipType'
import type { ArmourType, BaseParameterType, BaseShipType, Field } from '@/stores/type/ship.type'
import type { Choice } from '@/stores/type/storeTypes'

const shipStore = useShipStore()
const shipTypeStore = useShipTypeStore()
const { createShipDialog, fields, armourFields, options, items: ships } = storeToRefs(shipStore)
const { items: shipTypes } = storeToRefs(shipTypeStore)

const newShip = ref<BaseShipType>({ ...shipStore.getEmptyItem })
const createLoading = ref(false)

async function addShip(): Promise<void> {
  createLoading.value = true
  const res = await shipStore.create(newShip.value)
  createLoading.value = false
  if (res) {
    shipStore.resetState()
    shipStore.toggleCreateShipDialog()
    await shipStore.fetchPaginationItems()
    if (ships.value.length) {
      shipStore.setCurrentItem(ships.value[0])
    }
    newShip.value = { ...shipStore.getEmptyItem }
  }
}

const getLabelByField = (field: Field<BaseParameterType, ArmourType>): string => {
  return field.label ?? options.value[field.field]?.label
}

const getOptions = (field: keyof ArmourType): Choice[] => {
  return options.value[field]?.choices ?? []
}

const getDefaultValue = (fieldName: string): any => {
  return options.value[fieldName]?.default ?? null
}

const armourTextFields = computed(() => {
  return armourFields.value.filter((field) => field.type !== 'select')
})

watch(
  () => newShip.value.has_armour_param,
  (value) => {
    armourFields.value.forEach((field) => {
      newShip.value[field.field] = value ? getDefaultValue(field.field) : null
    })
  }
)
</script>

<template>
  <v-dialog v-model="createShipDialog" width="100vh" persistent>
    <v-card>
      <v-card-title>Create base ship</v-card-title>
      <div style="max-height: 650px" class="overflow-y-auto">
        <v-card flat>
          <v-card-title>Base parameters</v-card-title>
          <v-row class="mx-1 mb-3">
            <v-col cols="6">
              <v-text-field
                v-model="newShip['name']"
                color="primary"
                label="Name"
                type="text"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="shipTypes"
                color="primary"
                @update:modelValue="newShip['type'] = $event"
                item-title="name"
                item-value="id"
                label="Ship type"
                persistent-hint
                hide-details
              ></v-select>
            </v-col>
            <v-col v-for="(field, index) in fields" :key="index" cols="6">
              <v-text-field
                v-model="newShip[field.field]"
                color="primary"
                :label="getLabelByField(field)"
                :type="field.type"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="4">
                  <v-checkbox
                    v-model="newShip['has_armour_param']"
                    color="primary"
                    label="Has ARMOUR parameters"
                    density="compact"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <template v-if="newShip.has_armour_param">
          <v-card-title>ARMOUR parameters</v-card-title>

          <v-card flat>
            <v-row class="mx-1 mb-3">
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col v-for="(field, index) in armourTextFields" cols="6" :key="index">
                <v-text-field
                  v-model="newShip[field.field]"
                  color="primary"
                  :label="getLabelByField(field)"
                  :type="field.type"
                  hide-details="auto"
                  required
                ></v-text-field>
              </v-col>
              <v-col v-for="(field, index) in armourTextFields" cols="6" :key="index">
                <v-select
                  :items="getOptions(field.field)"
                  color="primary"
                  :model-value="newShip[field.field]"
                  @update:modelValue="newShip[field.field] = $event"
                  item-title="display_name"
                  item-value="value"
                  :label="getLabelByField(field)"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="createLoading"
          color="primary"
          class="text-none"
          variant="text"
          @click="shipStore.toggleCreateShipDialog()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          class="text-none"
          variant="text"
          :loading="createLoading"
          @click="addShip()"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
