<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'
import { useDocumentStore } from '@/stores/document'

const documentStore = useDocumentStore()
const { previewDocumentDialog } = storeToRefs(useShipStore())

const documentFiles = ref<File[]>([])

function add() {
  documentStore.addFiles(documentFiles.value)
  previewDocumentDialog.value = false
  documentFiles.value = []
}

const emit = defineEmits(['upload', 'close'])
</script>
<template>
  <v-dialog v-model="previewDocumentDialog" max-width="800px" persistent>
    <v-card>
      <v-card-title>Add document </v-card-title>
      <v-file-input
        v-model="documentFiles"
        chips
        class="px-4 mt-2"
        color="primary"
        density="compact"
        hide-details
        label="Upload document"
        prepend-icon="mdi-folder-zip"
        multiple
        truncate-length="20"
      ></v-file-input>
      <v-card-actions class="pt-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="text-none" small variant="text" @click="emit('close')">
          Cancel
        </v-btn>
        <v-btn color="primary" class="text-none" small variant="text" @click="add"> Add </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
