<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: 'Download template'
  },
  dialog: {
    type: Boolean,
    required: true
  },
  message: {
    type: String,
    required: false,
    default: 'Do you want to download the template?'
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits(['download', 'toggleDialog'])

const exportDialog = computed({
  get() {
    return props.dialog
  },
  set() {
    emit('toggleDialog')
  }
})
</script>

<template>
  <v-dialog v-model="exportDialog" max-width="400" persistent>
    <v-card class="pa-3">
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text class="pt-3 pl-4" v-html="message"></v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          color="primary"
          class="text-none"
          variant="text"
          @click="emit('toggleDialog')"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          class="text-none"
          variant="text"
          @click="emit('download')"
        >
          Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
