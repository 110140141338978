<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'
import DocumentTable from '@/components/document/DocumentTable.vue'
import { useDocumentStore } from '@/stores/document'

const documentStore = useDocumentStore()
const shipStore = useShipStore()
const { documentDialog, currentItem: currentShip } = storeToRefs(shipStore)
const { documentItems, uploadLoading } = storeToRefs(useDocumentStore())

const addDocument = ref<boolean>(false)

const documentFiles = computed({
  get: () => documentItems.value.filter((item) => item.file && !item.sent).map((item) => item.file),
  set: (value: File[]) => {
    documentStore.removeUnsentFiles()
    documentStore.addFiles(value)
  }
})

watch(addDocument, () => {
  if (!addDocument.value) {
    documentFiles.value = []
    documentStore.removeUnsentFiles()
  }
})

const uploadDisabled = computed<boolean>(() => {
  return documentItems.value.every((item) => item.sent)
})

function close() {
  addDocument.value = false
  emit('close')
}

const emit = defineEmits(['upload', 'close'])
</script>

<template>
  <v-dialog v-model="documentDialog" max-width="800px" persistent>
    <v-card>
      <v-card-title> Document of {{ currentShip?.name }} </v-card-title>
      <v-row class="mx-3 pb-3" no-gutters>
        <template v-if="addDocument">
          <v-col class="pr-3" cols="10">
            <v-file-input
              v-model="documentFiles"
              :disabled="uploadLoading"
              prepend-icon="mdi-folder-zip"
              chips
              color="primary"
              density="compact"
              hide-details
              label="Upload document"
              multiple
              truncate-length="20"
            ></v-file-input>
          </v-col>
          <v-col cols="2" style="display: flex; align-items: center" class="px-2">
            <v-btn
              :disabled="uploadLoading"
              prepend-icon="mdi-close"
              color="primary"
              block
              class="text-none"
              small
              variant="tonal"
              @click="addDocument = false"
            >
              Cancel
            </v-btn>
          </v-col>
        </template>
        <v-col class="mt-3" cols="12">
          <v-divider></v-divider>
          <DocumentTable
            :table-height="40"
            class="mb-2"
            :addDocument="documentStore.documentPermissions(currentShip) && !addDocument"
            :add-disabled="!uploadLoading && !!documentFiles.length"
            @add="addDocument = true"
          ></DocumentTable>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="uploadLoading"
          color="primary"
          class="text-none"
          small
          variant="text"
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="addDocument"
          :loading="uploadLoading"
          :disabled="uploadDisabled"
          color="primary"
          class="text-none"
          small
          variant="text"
          prepend-icon="mdi-cloud-upload"
          @click="emit('upload')"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
