<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useShipStore } from '@/stores/ship'
import ShipTableRow from '@/components/ships/ShipTableRow.vue'
import type { PropType } from 'vue'
import ShipTableHeader from '@/components/ships/ShipTableHeader.vue'
import { computed } from 'vue'
import { useApplicationStore } from '@/stores/application'
import { useShipTypeStore } from '@/stores/shipType'
import { useFairleadStore } from '@/stores/fairlead'
import type { ShipType } from '@/stores/type/ship.type'
import DeleteDialog from '@/components/dialog/DeleteDialog.vue'
import ExportDialog from '@/components/dialog/ExportDialog.vue'

const shipStore = useShipStore()

const {
  items: ships,
  currentItem: currentShip,
  fetchLoading: shipLoading,
  paginationServer,
  exportExcelDialog,
  exportAutoCADDialog,
  exportExcelLoading,
  exportAutoCADLoading
} = storeToRefs(shipStore)
const { fetchLoading: appLoading } = storeToRefs(useApplicationStore())
const { fetchLoading: shipTypeLoading } = storeToRefs(useShipTypeStore())
const { fetchLoading: fairleadLoading } = storeToRefs(useFairleadStore())

defineProps({
  chartEvent: { type: String as PropType<string>, required: true }
})

const itemsPerPage = ref(10)
const page = ref(1)

const loading = computed<boolean>(() => {
  return shipLoading.value || appLoading.value || shipTypeLoading.value || fairleadLoading.value
})

const updateOptions = async (options) => {
  if (ships.value.length) {
    const currentPage = ships.value.length / options.itemsPerPage
    if (page.value && options.page > currentPage) {
      await shipStore.fetchPaginationItems()
    }
    page.value = options.page
  }
}

const pageItems = computed<ShipType[]>(() => {
  let startIndex = itemsPerPage.value * (page.value - 1)
  let endIndex = itemsPerPage.value * page.value
  return ships.value ? ships.value.slice(startIndex, endIndex) : []
})

const count = computed<number>(() => {
  return paginationServer.value?.count ?? 0
})

function exportAutoCAD(): void {
  if (currentShip.value?.id) {
    shipStore.exportAutoCAD(currentShip.value?.id)
    shipStore.toggleExportAutoCADDialog(false)
  }
}

function exportExcel(): void {
  if (currentShip.value?.id) {
    shipStore.exportExcel(currentShip.value?.id)
    shipStore.toggleExportExcelDialog(false)
  }
}
</script>

<template>
  <div>
    <v-progress-linear v-if="loading" :indeterminate="loading" color="primary"></v-progress-linear>
    <div v-else style="height: 4px; width: 100%"></div>
    <v-data-table-server
      ref="shipTable"
      :items="ships"
      :items-per-page="itemsPerPage"
      @update:items-per-page="itemsPerPage = $event"
      @update:options="updateOptions"
      :items-length="count"
      density="compact"
      hide-no-data
      :itemsPerPageOptions="[{ title: '10', value: 10 }]"
      class="overflow-auto ship-table"
    >
      <template v-slot:headers>
        <ShipTableHeader />
      </template>
      <template v-slot:body>
        <template v-if="pageItems.length">
          <ShipTableRow
            :key="item.id"
            v-for="item in pageItems"
            :chart-event="chartEvent"
            :item="item"
          ></ShipTableRow>
        </template>
        <template v-else>
          <tr>
            <td class="text-center" colspan="14">No items found</td>
          </tr>
        </template>
      </template>
    </v-data-table-server>

    <DeleteDialog :store="shipStore" :name="currentShip?.name"></DeleteDialog>
    <ExportDialog
      :dialog="exportExcelDialog"
      :loading="exportExcelLoading"
      title="Export to Excel"
      @export="exportExcel"
      @toggle-dialog="shipStore.toggleExportExcelDialog()"
    ></ExportDialog>
    <ExportDialog
      :dialog="exportAutoCADDialog"
      :loading="exportAutoCADLoading"
      title="Export to AutoCAD"
      @export="exportAutoCAD"
      @toggle-dialog="shipStore.toggleExportAutoCADDialog()"
    ></ExportDialog>
  </div>
</template>

<style scoped>
.ship-table {
  height: calc(92vh - 104px);
}
</style>
