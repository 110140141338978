<script setup lang="ts">
import { useEventsBus } from '@rhdhv/vue-component-library'

const { emit: busEmit } = useEventsBus()

const emit = defineEmits(['resetZoom'])

function copyImageToClipboard() {
  let image = document.getElementById('chart') as HTMLCanvasElement
  image.toBlob((blob) => {
    if (blob) {
      navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })])
    }
  })
  const uploadMessage = {
    status: 200,
    data: [],
    method: 'POST',
    statusText: 'Copied to clipboard!'
  }
  console.log('Copied to clipboard!')
  busEmit('success', uploadMessage)
}
</script>

<template>
  <div style="position: absolute" class="ma-3">
    <v-tooltip location="right" open-delay="500">
      <template v-slot:activator="{ props }">
        <v-btn
          class="mr-0"
          v-bind="props"
          icon="mdi-arrow-expand-all"
          variant="text"
          @click="emit('resetZoom')"
        >
        </v-btn>
      </template>
      <span>Reset graph to origin location and zoom location</span>
    </v-tooltip>
    <v-tooltip location="right" open-delay="500">
      <template v-slot:activator="{ props }">
        <v-btn
          class="mr-0"
          v-bind="props"
          icon="mdi-content-copy"
          variant="text"
          @click="copyImageToClipboard()"
        >
        </v-btn>
      </template>
      <span>Copy the graph image to Clipboard</span>
    </v-tooltip>
    <v-tooltip location="right" open-delay="500">
      <template v-slot:activator="{ props }">
        <v-btn
          class="mr-0"
          v-bind="props"
          icon="mdi-help-circle"
          variant="text"
          @click="emit('resetZoom')"
        >
        </v-btn>
      </template>
      <span>Click + drag to change the viewport, CTRL + scroll to zoom</span>
    </v-tooltip>
  </div>
</template>
