<script lang="ts" setup>
import FairleadTable from '@/components/detail/FairleadTable.vue'
import type { PropType } from 'vue'
import type { PedestalType, ShipType, WinchType } from '@/stores/type/ship.type'
import CoordinateTable from '@/components/detail/CoordinateTable.vue'
import {
  PEDESTAL_COLOR,
  PEDESTAL_LABEL,
  WINCH_COLOR,
  WINCH_LABEL
} from '@/components/constantVariables'
import type { TableInfo } from '@/types/general'
import SpecificInfoTable from '@/components/detail/SpecificInfoTable.vue'

const INFO_TAB = 'info'
const FAIRLEAD_TAB = 'fairlead'
const WINCH_TAB = 'winch'
const PEDESTAL_TAB = 'pedestal'
type TabType = typeof INFO_TAB | typeof FAIRLEAD_TAB | typeof WINCH_TAB | typeof PEDESTAL_TAB

const tab = ref<TabType>(INFO_TAB)

const props = defineProps({
  item: { type: Object as PropType<ShipType>, required: true },
  chartEvent: { type: String as PropType<string>, required: true },
  tableHeight: { type: Number, default: 40 }
})

const winches = computed<WinchType[]>(() => {
  return props.item ? props.item.winch_set : []
})
const winchText: TableInfo = { text: 'Winch', symbol: 'W', label: WINCH_LABEL, color: WINCH_COLOR }

const pedestals = computed<PedestalType[]>(() => {
  return props.item ? props.item.pedestal_set : []
})
const pedestalText: TableInfo = {
  text: 'Pedestal',
  symbol: 'P',
  label: PEDESTAL_LABEL,
  color: PEDESTAL_COLOR
}
</script>

<template>
  <v-card class="px-0" flat>
    <v-tabs v-model="tab" bg-color="transparent" color="primary" grow>
      <v-tab :value="INFO_TAB" rounded="0" class="text-none"> Specific Info </v-tab>
      <v-tab v-if="item.has_ship_design" :value="FAIRLEAD_TAB" rounded="0" class="text-none">
        Fairleads
      </v-tab>
      <v-tab v-if="item.has_ship_design" :value="WINCH_TAB" rounded="0" class="text-none">
        Winches
      </v-tab>
      <v-tab v-if="item.has_ship_design" :value="PEDESTAL_TAB" rounded="0" class="text-none">
        Pedestals
      </v-tab>
    </v-tabs>
    <v-window v-model="tab" :style="`height: ${tableHeight}vh`">
      <v-window-item :value="INFO_TAB">
        <SpecificInfoTable :ship="item" :table-height="tableHeight"></SpecificInfoTable>
      </v-window-item>
      <v-window-item v-if="item.has_ship_design" :value="FAIRLEAD_TAB">
        <FairleadTable
          :chart-event="chartEvent"
          :table-height="tableHeight"
          :item="item"
        ></FairleadTable>
      </v-window-item>
      <v-window-item v-if="item.has_ship_design" :value="WINCH_TAB">
        <CoordinateTable
          :chart-event="chartEvent"
          :table-height="tableHeight"
          :items="winches"
          :tableInfo="winchText"
        ></CoordinateTable>
      </v-window-item>
      <v-window-item v-if="item.has_ship_design" :value="PEDESTAL_TAB">
        <CoordinateTable
          :chart-event="chartEvent"
          :table-height="tableHeight"
          :items="pedestals"
          :tableInfo="pedestalText"
        ></CoordinateTable>
      </v-window-item>
    </v-window>
  </v-card>
</template>
