<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { type FilterOptions, ObjectElement } from '@/stores/type/storeTypes'

const props = defineProps({
  modelValue: { type: Object as PropType<ObjectElement<any>>, required: true },
  field: { type: Object as PropType<FilterOptions<string>>, required: true },
  allValue: {
    type: [String, Number, null] as PropType<string | number | null>,
    required: false,
    default: null
  }
})

const options = computed(() => {
  return props.field?.options
})

const value = ref<ObjectElement<any>>({ text: '', value: null })

watch(value, (newValue: ObjectElement<any>) => {
  emit('update:modelValue', newValue)
  emit('update', {
    [props.field?.field]: newValue.value
  })
})

const emit = defineEmits(['update:modelValue', 'update'])

onMounted(() => {
  value.value = props.modelValue
})
</script>

<template>
  <v-radio-group v-model="value" inline hide-details>
    <v-list>
      <v-list-item class="py-0">
        <v-radio color="primary" :value="{ text: 'All', value: allValue }" label="All"></v-radio>
      </v-list-item>
      <template v-for="option in options">
        <v-list-item class="py-0">
          <v-radio color="primary" :label="option.text" :value="option"></v-radio
        ></v-list-item>
      </template>
    </v-list>
  </v-radio-group>
</template>

<style scoped>
.v-list {
  &:deep(.v-list-item) {
    min-height: 40px;
    &:deep(.v-list-item__content) {
      height: 40px;
    }
  }
}
</style>
