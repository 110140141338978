<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { PropType } from 'vue'

import { useShipStore } from '@/stores/ship'
import { useDocumentStore } from '@/stores/document'

import ShipActionMenu from '@/components/ships/ShipActionMenu.vue'
import DocumentDialog from '@/components/dialog/DocumentDialog.vue'
import ShipTable from '@/components/ships/ShipTable.vue'
import CreateShipDialog from '@/components/dialog/CreateShipDialog.vue'
import ImportShipDialogHandler from '@/components/import/ImportShipDialogHandler.vue'
import DownloadTemplateDialog from '@/components/dialog/DownloadTemplateDialog.vue'

defineProps({
  chartEvent: { type: String as PropType<string>, required: true }
})

const documentStore = useDocumentStore()
const shipStore = useShipStore()
const { documentDialog, currentItem, downloadTemplateDialog, downloadTemplateLoading } =
  storeToRefs(shipStore)

async function upload(): Promise<void> {
  if (currentItem.value?.id) {
    await documentStore.uploadDocumentFiles(currentItem.value.id)
  }
}

function close(): void {
  if (currentItem.value) {
    shipStore.addDocuments(currentItem.value)
  }
  documentStore.clearUnsavedDocuments()
  documentDialog.value = false
}

const downloadTemplate = async (): Promise<void> => {
  await shipStore.downloadTemplate()
  shipStore.toggleDownloadTemplateDialog(false)
}
</script>

<template>
  <div style="position: relative" class="fill-height">
    <ShipActionMenu />
    <ShipTable :chart-event="chartEvent" class="mt-2" />
    <CreateShipDialog />
    <ImportShipDialogHandler />
    <DownloadTemplateDialog
      :dialog="downloadTemplateDialog"
      :loading="downloadTemplateLoading"
      @toggle-dialog="shipStore.toggleDownloadTemplateDialog()"
      @download="downloadTemplate"
    />
    <DocumentDialog @upload="upload" @close="close" />
  </div>
</template>
