<script lang="ts" setup>
import { PropType } from 'vue'
import { storeToRefs } from 'pinia'
import CheckBoxField from '@/components/common/CheckBoxField.vue'
import { useApplicationStore } from '@/stores/application'
import { useShipStore } from '@/stores/ship'
import type { ValidationType } from '@/stores/type/validation.type'
import type { ApplicationType } from '@/stores/type/application.type'
import type { ShipType } from '@/stores/type/ship.type'

const props = defineProps({
  ship: { type: Object as PropType<ShipType>, required: true },
  tableHeight: { type: Number, default: 20 }
})

const { importSuccess } = storeToRefs(useShipStore())
const { items: applications } = storeToRefs(useApplicationStore())

const approvalItems = computed<ValidationType[]>(() => {
  return props.ship?.validation_set ?? []
})

function applicationName(applicationId: number): string {
  return applications.value.find((app: ApplicationType) => app.id === applicationId)?.name ?? ''
}
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <v-row v-if="!approvalItems.length">
      <v-col cols="12">
        <span class="subheading">No approvals</span>
      </v-col>
    </v-row>
    <v-table
      v-else
      :height="tableHeight + 'vh'"
      class="overflow-auto"
      density="compact"
      fixed-header
      hover
    >
      <thead>
        <tr>
          <th class="text-center border-right-grey">Application</th>
          <th class="text-center" style="width: 120px">Approved</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in approvalItems">
          <tr>
            <td class="border-right-grey">{{ applicationName(item.application) }}</td>
            <td>
              <CheckBoxField
                :item="item"
                class="approved-checkbox"
                parameter="approved"
                :disabled="importSuccess"
                @set="(value: boolean) => item.approved = value"
              />
            </td>
          </tr>
        </template>
        <tr>
          <td colspan="48" style="height: 3px"></td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<style scoped>
.approved-checkbox {
  width: 40px;
  margin: auto;
}
</style>
