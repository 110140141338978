<script lang="ts" setup>
import { useShipStore } from '@/stores/ship'
import { useDocumentStore } from '@/stores/document'
import { storeToRefs } from 'pinia'
import ShipPreview from '@/components/import/ShipPreview.vue'

const shipStore = useShipStore()
const { previewFullShipDialog, importLoading, importSuccess } = storeToRefs(shipStore)
const { uploadLoading: uploadDocumentLoading } = storeToRefs(useDocumentStore())

const emit = defineEmits(['import', 'back', 'close', 'finish'])
</script>

<template>
  <v-dialog
    v-model="previewFullShipDialog"
    :scrim="false"
    fullscreen
    persistent
    style="background-color: white"
    transition="dialog-bottom-transition"
  >
    <v-toolbar color="primary" density="compact">
      <v-toolbar-title>Preview</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <ShipPreview class="fill-dialog" style="flex: unset"></ShipPreview>
      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="!importSuccess">
          <v-btn
            :disabled="importLoading || uploadDocumentLoading"
            class="text-none pr-3"
            color="primary"
            variant="tonal"
            prepend-icon="mdi-arrow-left"
            @click="emit('back')"
          >
            Back
          </v-btn>
          <v-btn
            :disabled="importLoading || uploadDocumentLoading"
            class="text-none pr-3"
            color="primary"
            variant="tonal"
            prepend-icon="mdi-close"
            @click="emit('close')"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="importLoading || uploadDocumentLoading"
            center
            small
            class="text-none pr-3"
            color="primary"
            prepend-icon="mdi-import"
            variant="tonal"
            @click="emit('import')"
          >
            Import
          </v-btn>
        </template>
        <v-btn
          v-else
          :loading="uploadDocumentLoading"
          center
          color="primary"
          class="text-none pr-3"
          small
          variant="tonal"
          prepend-icon="mdi-close"
          @click="emit('finish')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.fill-dialog {
  height: calc(100% - 98px);
}
</style>
