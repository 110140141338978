<script lang="ts" setup>
import FilterMenu from '@/components/ships/FilterMenu.vue'
import { useShipStore } from '@/stores/ship'
import { storeToRefs } from 'pinia'

const shipStore = useShipStore()

const { downloadTemplateLoading } = storeToRefs(shipStore)
</script>

<template>
  <v-row no-gutters class="mt-3">
    <v-col class="px-2 py-1" sm="12" lg="5">
      <FilterMenu />
    </v-col>
    <v-col class="px-2 py-1" sm="12" lg="7" style="text-align: end">
      <v-btn
        prepend-icon="mdi-download"
        class="fill-height text-none"
        color="primary"
        :loading="downloadTemplateLoading"
        variant="tonal"
        @click="shipStore.toggleDownloadTemplateDialog(true)"
      >
        Download template
      </v-btn>
      <v-btn
        prepend-icon="mdi-plus"
        class="fill-height text-none ml-2"
        color="primary"
        variant="tonal"
        @click="shipStore.toggleCreateShipDialog()"
      >
        Create base ship
        <template v-slot:append>
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props">mdi-information</v-icon>
            </template>
            <span>Only information shown in the main list (name, type, capacity, LxBxD)</span>
          </v-tooltip>
        </template>
      </v-btn>
      <v-btn
        prepend-icon="mdi-import"
        class="fill-height text-none ml-2"
        color="primary"
        variant="tonal"
        @click="shipStore.toggleCreateFullShipDialog()"
      >
        Import ship
        <template v-slot:append>
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props">mdi-information</v-icon>
            </template>
            <span>Import new detailed specs including information using the template</span>
          </v-tooltip>
        </template>
      </v-btn>
    </v-col>
  </v-row>
</template>
